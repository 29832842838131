import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Events } from '../../services/events.service';
import { Global4Service } from '../../services/global4.service';
import { LoadingService } from '../../services/loading.service';
import { FormService } from '../../services/form.service';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})

export class LoginPage implements OnInit, OnDestroy {
  faSignInAlt = faSignInAlt;

  entity: { UserCode: string, UserPassword: string, SaveLogin: string } = {
    UserCode: '',
    UserPassword: '',
    SaveLogin: '0'
  };

  public ModuleName: string;
  public LoggingIn: boolean = false;

  constructor(
    public global: Global4Service,
    private events: Events,
    private loader: LoadingService,
    private menu: MenuController,
    private form: FormService
  ) {
    let Web: boolean = false;
    let OS: string = 'Unknown';
    let Browser: string = '';
    let Mobile: boolean = false;

    if ((document.URL.startsWith('http')) || (document.URL.startsWith('localhost'))) {
      Web = true;
    }
    if ((/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) ||
        (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent))) {
      Mobile = true;
    }

    if (Web) {
      if (navigator.platform === 'Win32') {
        OS = 'Windows';
      } else if (navigator.platform.indexOf("Mac") != -1) {
        OS = 'Mac OS';
      } else if (navigator.appVersion.indexOf('X11') != -1) {
        OS = 'UNIX';
      } else  if (navigator.appVersion.indexOf('Linux') != -1) {
        OS = 'Linux';
      }

      if (navigator.userAgent.indexOf(' Edg/') >= 0) {
        Browser = 'Microsoft Edge';
      } else if (navigator.userAgent.indexOf(' Firefox/') >= 0) {
        Browser = 'Mozilla Firefox';
      } else if (navigator.userAgent.indexOf(' OPR/') >= 0) {
        Browser = 'Opera';
      } else if (navigator.userAgent.indexOf(' .NET') >= 0) {
        Browser = 'Microsoft Internet Explorer';
      } else if (navigator.vendor.indexOf('Apple') !== -1) {
        Browser = 'Safari';
      } else if ((/Chrome/.test(navigator.userAgent)) && 
                 (/Google Inc/.test(navigator.vendor))) {
        Browser = 'Chrome';
      } else {
        Browser = 'Unknown';
      }

      if (Mobile === true) {
        this.global.Alert ('Warning!', 'The version of this system is designed for best experience in a desktop browser. The mobile APP version is coming soon!', ['OK']);
      } else if ((OS === 'Mac OS') &&
                 (Browser === 'Chrome')) {
        this.global.Alert ('Warning!', 'There are known issues with the system when running it on Chrome in the the Mac OS! It is highly recommended a different Browser is used.', ['OK']);
      } else if ((OS !== 'Mac OS') &&
                 (OS !== 'Windows')) {
        this.global.Alert ('Warning!', 'This system has been tested on a Microsoft Windows and Apple Mac OS environment. You may encounter unforeseen issues in your current environment. Apologies for the inconvenience.', ['OK']);
      }
    }

    this.ModuleName = 'Login';
    this.global.ClearUser();
    this.loader.preLoadRoute('/dashboard');
    this.loader.preLoadRoute('/projectslist');
    this.loader.preLoadRoute('/list');
    this.loader.preLoadRoute('/listeditable');
    this.loader.preLoadRoute('/projectslist');
    this.loader.preLoadRoute('/registeredprojectslist');
    this.global.GetIP();
  }

  ngOnInit() {
    if (this.global.CheckSubscription(this.ModuleName + 'form', 'formresult') === false) {
      this.events.subscribe(this.ModuleName.toLowerCase() + 'formresult', (result: any) => {
        try {          
          this.global.SetUser('UsersKey', result.Data[this.ModuleName][0]['UsersKey']);
          this.global.SetUser('EmployeesKey', result.Data[this.ModuleName][0]['EmployeesKey']);
          this.global.SetUser('FirstName', result.Data[this.ModuleName][0]['FirstName']);
          this.global.SetUser('UserName', result.Data[this.ModuleName][0]['UserName']);
          this.global.SetUser('PictureURL', result.Data[this.ModuleName][0]['PictureURL']);
          this.global.SetUser('Signature', result.Data[this.ModuleName][0]['Signature']);
          this.global.SetUser('Currency', result.Data[this.ModuleName][0]['Currency']);
          this.global.SetUser('SalesUnit', result.Data[this.ModuleName][0]['SalesUnit']);
          this.global.SetUser('UserType', result.Data[this.ModuleName][0]['UserType'].toString());
          this.global.SetUser('Internal', this.global.ToBoolean(result.Data[this.ModuleName][0]['Internal']).toString());
          this.global.SetUser('AccountManager', this.global.ToBoolean(result.Data[this.ModuleName][0]['AccountManager']).toString());
          this.global.SetUser('TechSales', this.global.ToBoolean(result.Data[this.ModuleName][0]['TechSales']).toString());
          this.global.SetUser('SolutionsEngineer', this.global.ToBoolean(result.Data[this.ModuleName][0]['SolutionsEngineer']).toString());
          this.global.SetUser('InHouseSales', this.global.ToBoolean(result.Data[this.ModuleName][0]['InHouseSales']).toString());
          this.global.SetUser('Encoder', this.global.ToBoolean(result.Data[this.ModuleName][0]['Encoder']).toString());
          this.global.SetUser('Administrator', this.global.ToBoolean(result.Data[this.ModuleName][0]['Administrator']));
          this.global.SetUser('IPAddress', $('input[name=IPAddress]').val());

          try {
            this.global.SetUser('ModulesString', JSON.stringify(result.Data['Modules']));
            this.global.SetUser('RightsString', JSON.stringify(result.Data['UserRights']));
          } catch (h) {
            this.global.SetUser('ModulesString', '');
            this.global.SetUser('RightsString', '');
          }

          this.global.SetSessionUser();

          this.entity.UserCode = '';
          this.entity.UserPassword = '';

          this.ClearErrors();
          this.menu.enable(true);
          this.global.SetParam ('Page', '/dashboard');
          this.global.Loading('Logging in...', 5, '', '/dashboard', 'ROOT');
        } catch (e) {
          this.LoggingIn = false;
          this.global.Toast('Access denied!', 1500, 'top', 'error');
        }
      });
    }
  }

  ngOnDestroy() {
    this.global.Unsubscribes(this.ModuleName + 'form');
  }

  ionViewDidEnter() {
    this.menu.enable(false);
    $('input[name=' + this.ModuleName + 'UserCode').focus();
  }

  ClearErrors () {
    this.form.ClearErrors(this.ModuleName);
  }

  Login() {
    let Valid: boolean = true;
    this.ClearErrors();
    this.LoggingIn = true;

    if (this.entity.UserPassword.trim() === '') {
      Valid = false;
      $('input[name=' + this.ModuleName + 'UserPassword').focus();
      this.form.ShowError(this.ModuleName + 'UserPasswordRequired');
    }
    
    if (this.entity.UserCode.trim() === '') {
      Valid = false;
      $('input[name=' + this.ModuleName + 'UserCode').focus();
      this.form.ShowError(this.ModuleName + 'UserCodeRequired');
    }

    if (Valid === true) {
      const Parameter: string =
          '<Login ' +
              'UserCode="' + this.global.XMLEncode(this.entity.UserCode) + '" ' +
              'UserPassword="' + this.global.XMLEncode(this.global.Encrypt(this.entity.UserPassword)) + '" ' +
          '/>';
      this.global.Get('Login', this.ModuleName, 'Users_Login', Parameter, 'Logging in...', 'Fatal error occurred while trying to login!', 'Unknown error occurred while trying to login!', 'Unknown fatal error occurred while trying to login!', this.ModuleName.toLowerCase() + 'formresult');
    } else {
      this.LoggingIn = false;
    }
  }
}
