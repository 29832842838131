import * as i0 from "@angular/core";
var Config = /** @class */ (function () {
    function Config() {
        /*
            NOTE:
              DefaultURL
              TemplateURL
              Directory
            - use global.XMLEncode
        */
        // this.SourceURL = environment.SourceURL;
        // this.URL = environment.URL;
        // this.NotificationURL = environment.NotificationURL;
        // this.UploadURL = environment.UploadURL;
        // this.DeleteURL = environment.DeleteURL;
        // this.DefaultURL = environment.DefaultURL;
        // this.TemplateURL = environment.TemplateURL;
        // this.Directory = environment.Directory;
        this.ApplicationName = 'United Neon MDS';
        this.SchemaName = 'UNMDS';
        this.NotificationAppID = '0';
        this.User = new Array();
        this.PageSize = 100;
        this.EditablePageSize = 100;
        this.Debug = false; // false
        this.Version = '2025.01.06.0048';
        if (this.Debug === true) {
            this.LocalURL = 'http://localhost:8100/';
            this.SourceURL = 'http://bispen/unmds/';
        }
        else {
            this.SourceURL = 'https://unmds.testyoursolutions.com/unmds/';
        }
        this.URL = this.SourceURL + 'r2t2post.php';
        this.NotificationURL = this.SourceURL + 'onesignal.php';
        this.UploadURL = this.SourceURL + 'upload.php';
        this.DeleteURL = this.SourceURL + 'delete.php';
        this.DefaultURL = this.SourceURL + 'uploads/';
        this.TemplateURL = this.SourceURL + 'templates/';
        this.Directory = this.SourceURL + 'uploads/';
        this.UserDefaults();
    }
    Config.prototype.UserDefaults = function () {
        this.User['UsersKey'] = '';
        this.User['FirstName'] = '';
        this.User['UserName'] = '';
        this.User['Administrator'] = false;
        this.User['Internal'] = false;
        this.User['UserType'] = '0';
        this.User['InHouseSales'] = false;
        this.User['Encoder'] = false;
        this.User['AccountManager'] = false;
        this.User['TechSales'] = false;
        this.User['SolutionsEngineer'] = false;
        this.User['SalesUnit'] = '';
        this.User['Vendor'] = '';
        this.User['Currency'] = '';
        this.User['ModulesString'] = '';
        this.User['RightsString'] = '';
        this.User['Modules'] = new Array();
        this.User['Rights'] = {};
        this.User['PictureURL'] = '';
        this.User['Signature'] = '';
        this.User['TokenID'] = '';
        this.User['IPAddress'] = '';
    };
    Config.prototype.SetUserConfig = function (Config, Value) {
        if (Value === void 0) { Value = null; }
        if ((['Modules', 'Rights']).indexOf(Config) !== -1) {
            if (this.User[Config + 'String'] !== '') {
                this.User[Config] = JSON.parse(this.User[Config + 'String']);
            }
        }
        else {
            switch (typeof (this.User[Config])) {
                case 'boolean':
                    this.User[Config] = this.ToBoolean(Value);
                    break;
                case 'number':
                    this.User[Config] = this.ParseFloat(Value);
                    break;
                default:
                    this.User[Config] = Value;
                    break;
            }
        }
    };
    Config.prototype.IsUndefined = function (Parameter, Arbitrary) {
        if ((Parameter === undefined) &&
            (Arbitrary === undefined)) {
            return '';
        }
        else if ((Parameter === null) ||
            (Arbitrary === null)) {
            return '';
        }
        else if (Parameter === undefined) {
            return Arbitrary;
        }
        else if (typeof (Parameter) === 'string') {
            return (Parameter.trim());
        }
        else {
            return Parameter;
        }
    };
    Config.prototype.ToBoolean = function (Input) {
        if ((Input === undefined) ||
            (Input === null)) {
            return (false);
        }
        else {
            switch (Input.toString().toUpperCase()) {
                case '1':
                case 'TRUE':
                    return true;
                    break;
                default:
                    return false;
                    break;
            }
        }
    };
    Config.prototype.ParseFloat = function (number, AllowNegative) {
        if (AllowNegative === void 0) { AllowNegative = true; }
        if (this.IsUndefined(number, '') !== '') {
            var returnStr = void 0;
            returnStr = this.IsUndefined(number, '0').toString();
            returnStr = returnStr.replace(/,/g, '');
            if ((parseFloat(returnStr) < 0) &&
                (AllowNegative === false)) {
                return (0);
            }
            else {
                return (parseFloat(returnStr));
            }
        }
        else {
            return (0);
        }
    };
    Config.ngInjectableDef = i0.defineInjectable({ factory: function Config_Factory() { return new Config(); }, token: Config, providedIn: "root" });
    return Config;
}());
export { Config };
