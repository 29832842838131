<ion-app>
  <ion-split-pane when="web.MenuShow">
    <ion-menu>
      <ion-header class="menu">
        <div class="menu" style="padding:10px">
          <div class="ion-padding" style="text-align:center">
            <img src="assets/imgs/MDS-Wide.png" alt="logo" style="width:50%" />
          </div>
          {{ 'Welcome, ' }}<br />
          <span><strong>{{ global.GetUser('FirstName') }}</strong></span>
        </div>
      </ion-header>
      <ion-content class="menu">
        <ion-list class="menu">
          <ion-item lines="none" button (click)="Toggle('Main');" class="menu">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Main']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Main']"></fa-icon>
            <ion-label>
              <fa-icon [icon]="faHome" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'MAIN' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Main']" class="menu">
            <ion-menu-toggle>
              <ion-item lines="none" button (click)="MenuRoute('/dashboard')" class="menu">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faTachometerAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dashboard' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item lines="none" button (click)="MenuRoute('/notifications')" class="menu" *ngIf="global.GetUser('Administrator')===true">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faBell" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Notifications' }}</strong>
                </ion-label>
                <ion-badge color="danger" *ngIf="global.Notification>0">&nbsp;&nbsp;{{ global.Notification }}&nbsp;&nbsp;</ion-badge>
              </ion-item> -->

              <ion-item lines="none" button (click)="MenuRoute('/changepassword')" class="menu" *ngIf="global.GetUser('UsersKey')!=='***admin***'">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faLock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Change Password' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/settings')" class="menu" *ngIf="global.GetUser('Administrator')===true">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faGalacticRepublic" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'System Settings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/appsettings')" class="menu" *ngIf="global.WithModule('Settings')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faCogs" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Application Settings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/logout')" class="menu">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faSignOutAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Logout' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item lines="none" button (click)="Toggle('Profiles');" class="menu" *ngIf="global.WithModule('Users') || global.WithModule('Clients') || global.WithModule('Products') || global.WithModule('Classifications') || global.WithModule('PriceLists')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Profiles']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Profiles']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faUserCircle" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'PROFILES' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Profiles']" class="menu">
            <ion-menu-toggle>
              <ion-item lines="none" button (click)="MenuRoute('/userslist')" class="menu" *ngIf="global.WithModule('Users')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faUsers" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Users' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/clientslist', true)" class="menu" *ngIf="global.WithModule('Clients')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faBriefcase" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Clients' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/classificationslist')" class="menu" *ngIf="global.WithModule('Classifications')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faNetworkWired" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Classifications' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/productslist')" class="menu" *ngIf="global.WithModule('Products')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faTv" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Products' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/pricelist', true)" class="menu" *ngIf="global.WithModule('PriceLists')">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Price List' }}</strong>
                </ion-label>
              </ion-item>

            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item lines="none" button (click)="Toggle('Projects');" class="menu" *ngIf="global.WithModule('Projects') || ((global.ToBoolean(global.GetUser('Internal'))===true) && (global.ToBoolean(global.GetUser('AccountManager'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)"> -->
          <ion-item lines="none" button (click)="Toggle('Projects');" class="menu" *ngIf="(global.ToBoolean(global.GetUser('Encoder'))===true) || ((global.ToBoolean(global.GetUser('Internal'))===true) && ((global.ToBoolean(global.GetUser('AccountManager'))===true) || (global.ToBoolean(global.GetUser('TechSales'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)) || global.WithModule('ProjectManagements')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Projects']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Projects']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faClipboardList" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'PROJECTS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Projects']" class="menu">
            <ion-menu-toggle>
              <!-- <ion-item lines="none" button (click)="MenuRoute('/projectslist', true)" class="menu" *ngIf="(global.WithModule('Projects')) || (((global.ToBoolean(global.GetUser('AccountManager'))===true) || (global.ToBoolean(global.GetUser('TechSales'))===true)) && (global.ToBoolean(global.GetUser('Administrator'))===false))"> -->
              <ion-item lines="none" button (click)="MenuRoute('/projectslist', true)" class="menu" *ngIf="((((global.ToBoolean(global.GetUser('AccountManager'))===true) && (global.ParseFloat(global.GetUser('UserType'))===4)) || (global.ToBoolean(global.GetUser('TechSales'))===true)) && (global.ToBoolean(global.GetUser('Administrator'))===false))">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Projects' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/teamprojectslist', true)" class="menu" *ngIf="(global.GetUser('UserType')==='2') && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Team Projects' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/registeredprojectslist')" class="menu" *ngIf="global.WithModule('ProjectManagements') || (global.InArray(global.GetUser('UserType'), [2, 3]) === true) || (global.ToBoolean(global.GetUser('Administrator'))===true) || (global.ToBoolean(global.GetUser('Encoder'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Registered Projects' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item lines="none" button (click)="MenuRoute('/projectsforsendinglist')" class="menu" *ngIf="(global.WithModule('Projects')) || (global.ToBoolean(global.GetUser('AccountManager'))===true)"> -->
              <ion-item lines="none" button (click)="MenuRoute('/projectsforsendinglist')" class="menu" *ngIf="(global.ToBoolean(global.GetUser('AccountManager'))===true) || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faEnvelope" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Projects for Sending' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item lines="none" button (click)="Toggle('Solutions');" class="menu" *ngIf="global.WithModule('Solutions') || global.WithModule('SolutionManagements') || ((global.ToBoolean(global.GetUser('Internal'))===true) && (global.ToBoolean(global.GetUser('MDS'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)"> -->
          <ion-item lines="none" button (click)="Toggle('Solutions');" class="menu" *ngIf="global.WithModule('SolutionManagements') || ((global.ToBoolean(global.GetUser('Internal'))===true) && (global.ToBoolean(global.GetUser('SolutionsEngineer'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Solutions']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Solutions']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faBrain" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'SOLUTIONS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Solutions']" class="menu">
            <ion-menu-toggle>
              <ion-item lines="none" button (click)="MenuRoute('/forsolutionslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('SolutionsEngineer'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faPenNib" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Projects for Solution Design' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/solutionslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('SolutionsEngineer'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faBrain" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Solutions' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/solutionsforrevisionslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('SolutionsEngineer'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faPen" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Solutions For Revisions' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/teamsolutionslist', true)" class="menu" *ngIf="(global.GetUser('UserType')==='2') && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faBrain" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Team Solutions' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/solutionsmanagementslist')" class="menu" *ngIf="global.WithModule('SolutionManagements') || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faBrain" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Solution Managements' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/solutionsapprovalslist')" class="menu" *ngIf="global.WithModule('SolutionApprovals') || (global.GetUser('UserType')==='1') || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Solutions for Approval' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item lines="none" button (click)="MenuRoute('/quotationuploadapprovalslist')" class="menu" *ngIf="global.WithModule('QuotationApprovals') || (global.GetUser('UserType')==='1') || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Uploaded Quotation Approvals' }}</strong>
                </ion-label>
              </ion-item> -->

            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item lines="none" button (click)="Toggle('Proposals');" class="menu" *ngIf="global.WithModule('Proposals') || global.WithModule('ProposalManagements') || ((global.ToBoolean(global.GetUser('Internal'))===true) && (global.ToBoolean(global.GetUser('TechSales'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)"> -->
          <ion-item lines="none" button (click)="Toggle('Proposals');" class="menu" *ngIf="global.WithModule('ProposalManagements') || ((global.ToBoolean(global.GetUser('Internal'))===true) && (global.ToBoolean(global.GetUser('TechSales'))===true)) || (global.ToBoolean(global.GetUser('Administrator'))===true)">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Proposals']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Proposals']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faFileInvoice" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'PROPOSALS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Proposals']" class="menu">
            <ion-menu-toggle>
              <ion-item lines="none" button (click)="MenuRoute('/forproposalslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('TechSales'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Projects for Proposal' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/proposalslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('TechSales'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoice" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Proposals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/proposalsforrevisionslist', true)" class="menu" *ngIf="(global.ToBoolean(global.GetUser('TechSales'))===true) && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faPen" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Proposals For Revisions' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/teamproposalslist', true)" class="menu" *ngIf="(global.GetUser('UserType')==='2') && (global.ToBoolean(global.GetUser('Administrator'))===false)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoice" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Team Proposals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/proposalsmanagementslist')" class="menu" *ngIf="global.WithModule('ProposalManagements') || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoice" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Proposals Managements' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" button (click)="MenuRoute('/proposalsapprovalslist')" class="menu" *ngIf="global.WithModule('ProposalApprovals') || (global.GetUser('UserType')==='1') || (global.ToBoolean(global.GetUser('Administrator'))===true)">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Proposals for Approval' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item lines="none" button (click)="Toggle('Reports');" class="menu" *ngIf="global.WithModule('')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Reports']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Reports']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faChartPie" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'REPORTS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Reports']" class="menu">
            <ion-menu-toggle>

              <ion-item lines="none" button (click)="MenuRoute('/rptpaymodecac')" class="menu">
                <ion-text slot="start" class="slot"></ion-text>
                <fa-icon slot="end" [icon]="faMoneyBillWaveAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Pay Mode: CAC' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list> -->
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet main>
      <uploadform></uploadform>
    </ion-router-outlet>
  </ion-split-pane>
</ion-app>
