import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AlertController, ToastController, LoadingController, NavController } from '@ionic/angular';
import { Events } from '../services/events.service';
import { Config } from '../config/config.service';
import * as $ from 'jquery';

Date.prototype.toISOString = function() {
  var tzo = -this.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
  return (
    this.getFullYear() +
    '-' + pad(this.getMonth() + 1) +
    '-' + pad(this.getDate()) +
    'T' + pad(this.getHours()) +
    ':' + pad(this.getMinutes()) +
    ':' + pad(this.getSeconds()) +
    dif + pad(tzo / 60) +
    ':' + pad(tzo % 60)
  );
}

@Injectable({
  providedIn: 'root'
})
export class Global4Service {

  public platformWidth: number;

  public ColXS: number = 360;
  public ColSM: number = 540;
  public ColMD: number = 735;
  public ColLG: number = 960;
  public ColXL: number = 1140;
  public ColXXL: number = 1325;
  public ColXXXL: number = 1500;

  public Headers = new HttpHeaders();

  public Notification: number = 0;

  public ParamPage: string;
  public ParamSourcePage: string;
  public ParamEditablePage: string;

  public ParamCurrentPage: string;
  public ParamID: string;
  public ParamDashboard: boolean = false;
  public ParamNotification: boolean = false;
  public ParamMyFunction: boolean = false;
  public ParamOption1: string = '';
  public ParamCustoms: any = [];

  public SublistErrors: any = new Array();

  private Subscriptions: any;
  private Live: boolean = false;
  
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private events: Events,
    private config: Config,
    private router: Router
  ) {
    this.Subscriptions = new Array();

    this.Headers.append('Access-Control-Allow-Origin', this.config.SourceURL);
    this.Headers.append('Allow-Control-Allow-Origin', '*');
    this.Headers.append('Access-Control-Allow-Methods', 'POST');
  }

  SetParam(ParamName: string, Value: string) {
    sessionStorage.setItem (this.config.ApplicationName + ParamName, Value);
  }

  GetParam(ParamName: string) {
    return sessionStorage.getItem (this.config.ApplicationName + ParamName);
  }

  SetSessionUser() {
    if (this.CountObject(this.config.User) > 0) {
      for (let config in this.config.User) {
        if (typeof(this.config.User[config]) === 'string') {
          sessionStorage.setItem (this.config.ApplicationName + config, this.config.User[config]);
        } else if (this.InArray(typeof(this.config.User[config]), ['boolean', 'number']) === true) {
          sessionStorage.setItem (this.config.ApplicationName + config, this.config.User[config].toString());
        } 
      }
    }
  }

  GetSessionUser() {
    if (this.CountObject(this.config.User) > 0) {
      for (let config in this.config.User) {
        this.config.SetUserConfig(config, sessionStorage.getItem (this.config.ApplicationName + config));
      }
    }

    // this.ParamID = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'ParamID'), '');
    this.ParamPage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'Page'), '');
    this.ParamEditablePage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'EditablePage'), '');
    this.ParamSourcePage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'SourcePage'), '');
    this.ParamCurrentPage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'CurrentPage'), '');
    this.ParamDashboard = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'Dashboard')), '');
    this.ParamMyFunction = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'MyFunction')), '');
    this.ParamNotification = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'Notification')), '');
    this.ParamOption1 = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'Option1'), '');
  }

  GetIP() {
    $.getJSON("https://api.ipify.org?format=json", function(data) {
      $('input[name=IPAddress]').val(data.ip);
    });
  }

  GetSchemaName() {
    return (this.config.SchemaName);
  }

  GetVersion() {
    return (this.config.Version);
  }

  CheckVersion() {
    let Loaded: boolean = this.ToBoolean(this.IsUndefined(this.GetParam('Loaded'), 'false'));
    const Response: any = this.Post('', '', 'GetVersion', '', false);
    if (this.IsUndefined(Response, '') !== '') {

      if (Response['Version'][0]['Version'] !== this.config.Version) {
        if (Loaded === false) {
          this.SetParam('Loaded', 'true');
          window.location.href = window.location.href;
        } else {
          this.Alert ('Warning!', 'You are possibly using a cached version of the system! Please click Ctrl + F5 to refresh your browser. Apologies for the inconvenience.', ['OK']);
        }
      } else {
        this.SetParam('Loaded', 'false');
      }
    } else {
      this.SetParam('Loaded', 'true');
    }
  }

  OnDebug() {
    return (this.config.Debug);
  }

  SetUser(Config: string, Value: any) {
    this.config.User[Config] = Value;
  }

  GetUser(Config: string) {
    return (this.config.User[Config]);
  }

  SourceURL() {
    if (this.config.Debug === true) {
      return (this.config.LocalURL);
    } else {
      return (this.config.SourceURL);
    }
  }

  URL() {
    return (this.config.URL);
  }

  NotificationURL() {
    return (this.config.NotificationURL);
  }

  UploadURL() {
    return (this.config.UploadURL);
  }

  DeleteURL() {
    return (this.config.DeleteURL);
  }

  DefaultURL() {
    return (this.config.DefaultURL);
  }

  TemplateURL() {
    return (this.config.TemplateURL);
  }

  GetPageSize() {
    return (this.config.PageSize);
  }

  GetEditablePageSize() {
    return (this.config.EditablePageSize);
  }

  ClearUser() {
    this.config.UserDefaults();
    this.SetSessionUser();
  }

  Valid(ValidateUser: boolean = true) {
    let valid: boolean = true;
    this.GetSessionUser();
    let ToRoot: string = this.GetParam('ToRoot');
    ToRoot = this.IsUndefined(ToRoot, '');
	  if (((this.config.User['UsersKey'] === '') ||
         (this.config.User['UsersKey'] === undefined) ||
         (this.config.User['UsersKey'] === null)) &&
        (ValidateUser === true)) {
      valid = false;
      this.Toast('Access denied!', 1500, 'top', 'error');
      this.ClearUser ();
      this.navCtrl.navigateRoot ('/login');
    } else if ((this.Live === false) &&
               (this.router.url !== this.ParamPage)) {
      this.ParamOption1 = '';
      this.SetParam('Option1', '');
      this.Live = true;
      this.Loading ('Loading', 0, '', this.ParamPage, 'BACKWARD');
    } else {
      this.Live = true;
      this.SetParam('ToRoot', '');
    }
    return (valid);
  }

  InArray(Find: any, ArrayParameter: any) {
    if ((ArrayParameter).indexOf(Find) === -1) {
      return (false);
    } else {
      return (true);
    }
  }

  WithModule(ModuleName: string) {
    if (this.config.User['Administrator'] === true) {
      return (true);
    } else {
      if (Array.isArray(this.config.User['Modules']) === true) {
        return (this.InArray(ModuleName, this.config.User['Modules']));
      } else {
        return (false);
      }
    }
  }

  WithFunction(ModuleName: string, FunctionName: string) {
    if (this.config.User['Administrator'] === true) {
      return (true);
    } else {
      try {
        return (this.InArray(FunctionName, this.config.User['Rights'][ModuleName]));
      } catch (e) {
        return (false);
      }
    }
  }

  LoadScript (URL: string) {
    $.ajax ({
      url: URL,
      dataType: 'script',
      async: false
    });
  }

  async Alert(Title: string, Message: string, Buttons: any) {
    const alert = await this.alertController.create ({
      header: Title,
      message: Message,
      buttons: Buttons
    });

    await alert.present();
  }

  async Toast(Message: string, Duration: number, Position: any, Style: string) {
    const toast = await this.toastController.create({
      message: Message,
      duration: Duration,
      position: Position,
      cssClass: Style
    });
    toast.present();
  }

  IsUndefined(Parameter: any, Arbitrary: any) {
    if ((Parameter === undefined) &&
        (Arbitrary === undefined)) {
      return '';
    } else if ((Parameter === null) ||
               (Arbitrary === null)) {
      return '';
    } else if ((Parameter === undefined) ||
               (Parameter === null)) {
      return Arbitrary;
    } else if (typeof(Parameter) === 'string') {
      return (Parameter.trim());
    } else {
      return Parameter;
    }
  }

  SetPrefix(Parameter: string) {
    Parameter = this.IsUndefined(Parameter, '');
    if (Parameter !== '') {
      Parameter = Parameter + ' ';
    }
    return (Parameter);
  }

  SetPostfix(Parameter: string) {
    Parameter = this.IsUndefined(Parameter, '');
    if (Parameter !== '') {
      Parameter = ' ' + Parameter;
    }
    return (Parameter);
  }

  Post(TransactionURL: string, Schema: string, StoredProcedure: string, Parameter: string, WithParameter: boolean, ClientExecuteOnSuccess: string = '', ClientExecuteOnFail: string = '', ServerIncludeLibrary: string = '', ServerExecuteBefore: string = '', ServerExecuteAfter: string = '', Async: boolean = false, TransactionType: number = 0, Publish: string = '', Tag: string = '', options: any = {}) {
    let Results: any;

    if (this.IsUndefined(TransactionURL, '') === '') {
      TransactionURL = this.config.URL;
    }

    if (Schema.trim() === '') {
      if (this.config.SchemaName.trim() !== '') {
        Schema = this.config.SchemaName.replace(/ /g, '');
      } else {
        Schema = this.config.ApplicationName.replace(/ /g, '');
      }
    }

    let debug: boolean = this.config.Debug;
    let events: any = this.events;
    $.ajax ({
      type: 'POST',
      data: 'StoredProcedure=' + encodeURIComponent(Schema + '.' + StoredProcedure.trim()) +
          '&Parameter=' + encodeURIComponent(Parameter.trim()) +
          '&WithParameter=' + encodeURIComponent(WithParameter.toString().trim()) +
          '&ServerIncludeLibrary=' + encodeURIComponent(ServerIncludeLibrary.trim()) +
          '&ServerExecuteBefore=' + encodeURIComponent(ServerExecuteBefore.trim()) +
          '&ServerExecuteAfter=' + encodeURIComponent(ServerExecuteAfter.trim()),
      url: TransactionURL,
      async: Async,
      success: function(Result) {
        if (debug === true) {
          console.log('>>>> ', Result);
        }
        Results = Result;
        switch (TransactionType) {
          case 1:
            events.publish (Publish,
                {
                  Data: Result
                }
              );
            break;
          case 2:
            if (Async === true) {
              events.publish (Publish,
                {
                  Data: options.Data,
                  Response: Result[Tag][0],
                  CurrentRow: options.Row
                }
              );
            }
            break;
        }
      },
      error: function (err) {
        if (debug === true) {
          console.log('>>>> err: ', err);
        }
        switch (TransactionType) {
          case 1:
            if (debug === true) {
              console.log('>>>> search failed!');
            }
            events.publish (Publish,
                {
                  Data: null
                }
              );
            break;
          case 2:
            if ((debug === true) &&
                (Async === true)) {
              console.log ('>>>> transaction failed!');
            }
            break;
        }
      }
    });

    return Results;
  }

  async Transaction(ModuleName: string, StoredProcedure: string, Parameter: string, Publish: string, LoadMessage: string, FatalErrorMessage: string, SuccessMessage: string, UnknownErrorMessage: string, options: any = {}, ShowResult: boolean = true, Tag: string = '', Async: boolean = false) {
    this.loadingController.create ({
      spinner: 'circles',
      message: LoadMessage,
      animated: true,
      translucent: true
    }).then ((res) => {
      if (this.config.Debug === true) {
        console.log ('>>>> ' + StoredProcedure);
        console.log ('>>>> ' + Parameter);
      }
      const Response = this.Post('', '', StoredProcedure, Parameter, true, '', '', '', '', '', Async, 2, Publish, Tag, options);
      if (Async === false) {
        if (this.config.Debug === true) {
          console.log ('>>>> ' + JSON.stringify(Response));
        }

        if (Tag === '') {
          Tag = ModuleName;
        }
        
        let Error: boolean = true;
        try {
          if (Response[Tag][0]['Success'].toString() === '-1') {
            this.Toast (FatalErrorMessage, 3000, 'top', 'error');
          } else if (Response[Tag][0]['Success'].toString() === '0') {
            this.SetParam(ModuleName + 'FunctionSuccess', 'true');
            this.Toast (Response[Tag][0]['Message'], 3000, 'top', 'error');
          } else {
            Error = false;
            if ((this.IsUndefined(options.ReturnResult, false) === false) &&
                (ShowResult === true)) {
              this.Toast (SuccessMessage, 3000, 'top', 'success');
            }

            if (this.IsUndefined(options.List, false) === true) {
              this.events.publish (Publish, 
                  {
                    Data: this.IsUndefined(options.Data, []),
                    Response: Response[Tag][0]
                  }
                );
            } else if (this.IsUndefined(options.ReturnResult, false) === true) {
              this.events.publish (Publish,
                  { 
                    Response: Response
                  }
                );
            } else {
              this.events.publish (Publish,
                { 
                  Response: Response
                }
              );
            }
          }
        } catch (e) {
          this.Toast (UnknownErrorMessage, 3000, 'top', 'error');
        }

        if ((Error === true) &&
            (this.IsUndefined(options.ReturnError, false) === true)) {
          this.events.publish (Publish,
            {
              Response: Response,
              Options: options.Data,
              Row: options.Row
            });
        }
      }
      res.dismiss ();
    });
  }

  async Get(Function: string, Tag: string, StoredProcedure: string, Parameter: string, LoadMessage: string, FatalErrorMessage: string, UnknownErrorMessage: string, UnknownFatalErrorMessage: string, Publish: string, hotColumnDecode: any = [], WithSelector: boolean = true, Editable: boolean = false, Custom: any = []) {
    let Error: boolean = false;
    let Response: any;
    this.loadingController.create ({
      spinner: 'circles',
      message: LoadMessage,
      animated: true,
      translucent: true
    }).then ((res) => {
      let WithParameter: boolean = false;
      let Customized: any = {}
      if (Parameter.trim() !== '') {
        WithParameter = true;
      }
      if (this.config.Debug === true) {
        console.log ('>>>> ' + StoredProcedure);
        console.log ('>>>> ' + Parameter);
      }

      let Async: boolean = false;
      let TransactionType: number = 0;

      if (this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === true) {
        Async = true;
        TransactionType = 1;
      }

      Response = this.Post('', '', StoredProcedure, Parameter, WithParameter, '', '', '', '', '', Async, TransactionType, Publish);
      if ((this.config.Debug === true) &&
          (this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === false)) {
        console.log ('>>>> ' + JSON.stringify(Response));
      }
      let Success: boolean = false;
      try {
        if (Response['Success'].toString() === '0' || Response['Success'].toString() === '-1') {
          this.Toast(FatalErrorMessage, 3000, 'top', 'error');
        } else {
          this.Toast(UnknownErrorMessage, 3000, 'top', 'error');
        }
      } catch (e) {
        Success = true;
      }

      try {
        Error = !Success;
      } catch (f) {
         Error = true;
      }

      if (this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === false) {
        if (Error === true) {
          this.Toast(UnknownFatalErrorMessage, 3000, 'top', 'error');
        } else if ((Error === false) &&
                   (Response !== undefined)) {
          this.events.publish (Publish, 
            {
              Data: Response
            }
          );
        } else {
          this.events.publish (Publish, 
              {
                Data: []
              }
            );
        }
      }

      res.dismiss ();
    });
  }

  async CheckNotification() {
    // if ((this.config.User['UsersKey'] !== '***admin***') &&
    //     (this.config.User['UsersKey'] !== '')) {
    //   let Parameter: string;

    //   Parameter = '<Notifications ' +
    //                 'EmployeesKey="' + this.XMLEncode(this.config.User['EmployeesKey']) + '" ' +
    //               '/>';
    //   if (this.config.Debug === true) {
    //     console.log ('>>>> ' + Parameter);
    //   }
    //   const Response = await this.Post('', '', 'Notifications_Count', Parameter, true, '', '', '', '', '');
    //   if (this.config.Debug === true) {
    //     console.log ('>>>> ' + JSON.stringify(Response));
    //   }
    //   let Success: boolean = false;
    //   try {
    //     if ((Response['Success'].toString() === '0') ||
    //         (Response['Success'].toString() === '-1')) {
    //       this.Notification = 0;
    //     } else {
    //       this.Notification = 0;
    //     }
    //   } catch (e) {
    //     Success = true;
    //   }

    //   try {
    //     if (Success === true) {
    //       if (Response !== undefined) {
    //         this.Notification = Response['Notifications'][0]['Counts'];
    //       } else {
    //         this.Notification = 0;
    //       }
    //     } else {
    //       this.Notification = 0;
    //     }
    //   } catch (f) {
    //     this.Notification = 0;
    //   }
    // }
  }

  Encrypt(Parameter: string) {
    Parameter = this.IsUndefined(Parameter, '');
    if (Parameter !== '') {
      Parameter = btoa (Parameter);
    }
    return Parameter;
  }

  XMLEncode(Parameter: string) {
    if (this.IsUndefined(Parameter, '') === '') {
      return ('');
    } else if (typeof(Parameter) === 'number') {
      return (Parameter);
    } else if (typeof(Parameter) === 'string') {
      return (
            this.IsUndefined(Parameter, '').replace(/\&/g, '&#' + ('&').charCodeAt(0) + ';')
                                           .replace(/</g, '&#' + ('<').charCodeAt(0) + ';')
                                           .replace(/>/g, '&#' + ('>').charCodeAt(0) + ';')
                                           .replace(/\'/g, '&#' + ('\'').charCodeAt(0) + ';')
                                           .replace(/\"/g, '&#' + ('"').charCodeAt(0) + ';')
                                           .replace(/\+/g, '&#' + ('+').charCodeAt(0) + ';')
                                           .replace(/%/g, '&#' + ('%').charCodeAt(0) + ';')
                                           .replace(/'/g, '&#' + ("'").charCodeAt(0) + ';')
                                           .replace(/\r/g, '&#xD;')
                                           .replace(/\n/g, '&#xA;')
          );
    } else {
      return ('');
    }
  }

  JSONSafe(Parameter: string) {
    if (this.IsUndefined(Parameter, '') === '') {
      return ('');
    } else if (typeof(Parameter) === 'number') {
      return (Parameter);
    } else if (typeof(Parameter) === 'string') {
      return (
            this.IsUndefined(Parameter, '').replace(/"/g, '\\"')
          );
    } else {
      return ('');
    }
  }

  Loading (Message: string, Duration: number, Class: string, Page: string, Direction: string) {
    const loading = this.loadingController.create ({
      spinner: 'circles',
      // duration: Duration,
      duration: 1,
      message: Message,
      animated: true,
      translucent: true
    }).then ((res) => {
      res.present ();
      if ((Page !== undefined) &&
          (Page !== '')) {
        if (Direction.toUpperCase() === 'ROOT') {
          this.navCtrl.navigateRoot(Page);
        } else if (Direction.toUpperCase() === 'FORWARD') {
          this.navCtrl.navigateForward(Page);
        } else if (Direction.toUpperCase() === 'BACKWARD') {
          this.navCtrl.navigateBack(Page);
        }
      }
      this.loadingController.dismiss ();
    });
  }

  Replace(StringParameter: string, Find: string, ReplaceAs: string) {
    if (StringParameter !== undefined) {
      const RegExp = eval('/' + Find + '/g');
      return (StringParameter.replace (RegExp, ReplaceAs));
    } else {
      return ('');
    }
  }

  ReplaceLast(StringParameter: string, CharacterLength: number, ReplaceAs: string) {
    if ((this.IsUndefined(StringParameter, '') !== '') &&
        (CharacterLength > 0) &&
        (this.IsUndefined(ReplaceAs, '') !== '')) {
      const Len: number = StringParameter.length;
      return (StringParameter.substring(0, Len - CharacterLength) + ReplaceAs);
    } else {
      return ('');
    }
  }

  CopyArray(SourceArray: any) {
    let ReturnArray: any;
    ReturnArray = new Array();

    if (Array.isArray(SourceArray) === true) {
      for (const index in SourceArray) {
        ReturnArray.push (SourceArray[index]);
      }
    }
    return (ReturnArray);
  }

  CopyObject(SourceObject: any) {
    let ReturnObject: any = JSON.parse(JSON.stringify(SourceObject));
    return (ReturnObject);
  }

  CountObject(ObjectParameter: any) {
    try {
      let Count: number = 0;
      for (const node in ObjectParameter) {
        Count++;
      }
      return (Count);
    } catch (e) {
      return (0);
    }
  }

  CountArray(ArrayParameter: any) {
    if (Array.isArray(ArrayParameter) === false) {
      return (0);
    } else {
      if (ArrayParameter.length > 0) {
        return (1);
      } else {
        let Count: number = 0;
        for (const node in ArrayParameter) {
          Count++;
        }
        return (Count);
      }
    }
  }

  InObjects(Value: string, Object: any, NodeName: string) {
    if ((Value.trim() !== '') &&
        (this.IsUndefined(Object, '<*>') !== '<*>')  &&
        (NodeName.trim() !== '')) {
      if (Object.length > 0) {
        let Found: boolean = false;
        let Count: number = 0;

        while ((Count < Object.length) &&
               (Found === false)) {
          if (Value.trim() === Object[Count][NodeName].trim()) {
            Found = true;
          }
          Count++;
        }
        return (Found);
      } else {
        return (false);
      }
    } else {
      return (false);
    }
  }

  ToBoolean(Input: any) {
    if ((Input === undefined) ||
        (Input === null)) {
      return (false);
    } else {
      switch (Input.toString().toUpperCase()) {
        case '1':
        case 'TRUE':
          return true;
          break;
        default:
          return false;
          break;
      }
    }
  }

  isDate(value: any) {
    switch (typeof value) {
      case 'number':
        return true;
      case 'string':
        return !isNaN(Date.parse(value));
      case 'object':
        if (value instanceof Date) {
          return !isNaN(value.getTime());
        }
      default:
        return false;
    }
  }

  ToDate(DateParam: string) {
    let DateValue = new Date(DateParam);
    const hoursDiff = DateValue.getHours() - DateValue.getTimezoneOffset() / 60;
    const minutesDiff = (DateValue.getHours() - DateValue.getTimezoneOffset()) % 60;
    DateValue.setHours(hoursDiff);
    DateValue.setMinutes(minutesDiff);
    return (DateValue);
  }

  DateToString(DateParam: string) {
    if ((DateParam === undefined) ||
        (DateParam === null)) {
      return ('');
    } else if (DateParam.trim() === '') {
      return ('');
    } else {
      let DateSplit: any;
      DateSplit = new Array();
      DateSplit = DateParam.split ('T');
      if (this.isDate(DateSplit[0]) === false) {
        return ('');
      } else {
        return (DateSplit[0]);
      }
    }
  }

  toISOString(_Date: Date) {
    if (_Date === null) {
      return (null);
    } else {
      return (_Date.toISOString());
    }
  }

  DateTimeToString(DateTimeParam: string) {
    if (this.IsUndefined(DateTimeParam, '').trim() === '') {
      return ('');
    } else {

      let DateTimeVar: any;
      DateTimeVar = new Array();
      DateTimeVar = DateTimeParam.split('+');

      if (DateTimeVar.length > 1) {
        let DateTimeVar2: any;
        DateTimeVar2 = DateTimeVar[0].split('T');

        if (DateTimeVar2.length > 1) {
          let DateTimeVar3: any;
          DateTimeVar3 = DateTimeVar2[1].split(':');

          if (DateTimeVar3.length > 1) {
            return (DateTimeVar2[0].replace ('T', ' ')
                                  .replace ('Z', ' ') + ' ' + DateTimeVar3[0] + ':' + DateTimeVar3[1]);
          } else {
            return (DateTimeParam);
          }
        } else {
          return (DateTimeParam);
        }
      } else {
        return (DateTimeParam);
      }
    }
  }

  TimeToString(TimeParam: string) {
    if ((TimeParam.trim() === '') ||
        (TimeParam === undefined) ||
        (TimeParam === null)) {
      return ('');
    } else {
      let TimeVar: any;
      TimeVar = new Array();
      TimeVar = TimeParam.split('+');

      return (TimeVar[0].replace ('Z', ' '));
    }
  }

  DateTimeToTime(DateTimeParam: string) {
    if ((DateTimeParam.trim() === '') ||
        (DateTimeParam === undefined) ||
        (DateTimeParam === null)) {
      return ('');
    } else {
      let DateTimeVar: any;
      DateTimeVar = new Array();
      DateTimeVar = DateTimeParam.split('T');

      let TimeZoneVar: any;
      TimeZoneVar = new Array();
      TimeZoneVar = DateTimeVar[1].split('+');

      let TimeParts: any;
      TimeParts = new Array();
      TimeParts = TimeZoneVar[0].replace ('T', ' ')
                                .replace ('Z', ' ')
                                .split (':');

      let Hour: number = parseInt(TimeParts[0], 10);
      let AMPM: string;

      if (Hour - 12 > 0) {
        Hour = Hour - 12;
        AMPM = 'PM';
      } else {
        AMPM = 'AM';
      }

      return (Hour.toString() + ':' + TimeParts[1] + ' ' + AMPM);
    }
  }

  ConvertMMDDYYYY(DateString: string, Delimeter: string, ConvertFormat: string) {
    let DateArr = DateString.split(Delimeter);
    return (
              ConvertFormat.replace(/YYYY/g, DateArr[2])
                           .replace(/MM/g, DateArr[0])
                           .replace(/DD/g, DateArr[1])
           );
  }

  MonthDiff(Date1: Date, Date2: Date) {
    let Months: number;
    Months = (Date2.getFullYear() - Date1.getFullYear()) * 12;
    Months -= Date1.getMonth();
    Months += Date2.getMonth();
    return (Months);
  }

  decodeString(str: string) {
    if ((str !== null) &&
        (str !== undefined)) {
      return str.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
      });
    } else {
      return ('');
    }
  }

  decodeHtmlEntity(str: string) {
    if ((str === null) ||
        (str === undefined)) {
      return ('');
    } else {
      str = str.toString();
      return (
          this.decodeString(str).replace (/\\\\/g, '')
                                .replace (/\\&#10;/g, '\\n')
        );
    }
  }

  DecodeArray(Arr: any) {
    let ReturnArr: any = new Array();
    if (this.CountArray(Arr) > 0) {
      for (let x = 0; x < Arr.length - 1; x++) {
        ReturnArr.push(this.decodeHtmlEntity(Arr[x]));
      }
    }
    return (ReturnArr);
  }

  DecodeDropDowns(Options: any, Objects: any) {
    let Returns: any = new Array();

    if ((this.CountObject(Options) > 0) &&
        (this.CountObject(Objects) > 0)) {
      for (let object in Objects) {
        Returns[object] = new Array();
        try {
          if (this.IsUndefined(Objects[object]['Node'], '') === '') {
            for (let y = 0; y < Options[object].length; y++) {
              Returns[object].push(this.decodeHtmlEntity(Options[object][y]));
            }
          } else {
            for (let y = 0; y < Options[object].length; y++) {
              Returns[object].push(this.decodeHtmlEntity(Options[object][y][Objects[object]['Node']]));
            }
          }
        } catch (e) {
          Returns[object] = new Array();
        }
      }
    }
    return(Returns);
  }

  GenerateCode(___Length) {
    ___Length = this.IsUndefined(___Length, 10);

    let __Code = '';
    const __Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < ___Length; i++) {
      __Code += __Chars.charAt(Math.floor(Math.random() * __Chars.length));
    }

    return (__Code);
  }

  GetTodayString() {
    const today = new Date();
    let Month: string = (today.getMonth() + 1).toString();
    if (Month.length === 1) {
      Month = '0' + Month;
    }
    const date = today.getFullYear() + '-' + Month + '-' + ('0').repeat(2 - today.getDate().toString().length) + today.getDate();
    return (date);
  }

  GetTodayTimeString() {
    const today = new Date();
    let Month: string = (today.getMonth() + 1).toString();
    if (Month.length === 1) {
      Month = '0' + Month;
    }
    const date = today.getFullYear() + '-' + Month + '-' + ('0').repeat(2 - today.getDate().toString().length) + today.getDate();
    const time = today.getHours() + ':' + ('0').repeat(2 - today.getMinutes().toString().length) + today.getMinutes();
    return (date + ' ' + time);
  }

  SetDateTime(DateTimeString: string) {
    let _arrDate: any;
    _arrDate = DateTimeString.replace (/T/g, ' ')
                             .replace (/Z/g, '')
                             .split (' ');
    let _dayParts: any;
    _dayParts = this.GetDateParts(_arrDate[0]);
    if (_arrDate.length === 1) {
      return (_dayParts['Year'].toString() + '-' + _dayParts['Month'].toString() + '-' + _dayParts['Day'].toString());
    } else {
      let _timeParts: any;
      _timeParts = this.GetTimeParts(_arrDate[1]);
      return (_dayParts['Year'].toString() + '-' + _dayParts['Month'].toString() + '-' + _dayParts['Day'].toString() + ' ' + _timeParts['Hour'].toString() + ':' + _timeParts['Minute'] + ' ' + _timeParts['AMPM']);
    }
  }

  toFixed(x: any) {
    if (Math.abs(x) < 1.0) {
      const e = parseInt(x.toString().split('e-')[1], 10);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      let f = parseInt(x.toString().split('+')[1], 10);
      if (f > 20) {
        f -= 20;
        x /= Math.pow(10, f);
        x += (new Array(f + 1)).join('0');
      }
    }
    return (x);
  }

  ParseFloat(number: any, AllowNegative: boolean = true) {
    if (typeof(number) === 'string') {
      if (this.IsUndefined(number, '') !== '') {
        let returnStr: string;
        returnStr = this.IsUndefined(number, '0').toString();
        returnStr = returnStr.replace (/,/g, '');
        if ((parseFloat(returnStr) < 0) &&
            (AllowNegative === false)) {
          return (0);
        } else {
          return (parseFloat(returnStr));
        }
      } else {
        return (0);
      }
    } else if (typeof(number) === 'number') {
      return (number);
    } else {
      return (0);
    }
  }

  CheckInteger(___NumParam: any, ___ArbitraryValue: number, ___AllowNegative: boolean = true) {
    if ((___NumParam == null) ||
        (typeof(___NumParam) === undefined) ||
        (parseInt(___NumParam, 10).toString() === 'NaN')) {
      return (this.IsUndefined(___ArbitraryValue, 0));
    } else {
      let ReturnNumber: number;
      ReturnNumber = parseInt(___NumParam, 10);
      if ((___AllowNegative === false) &&
          (ReturnNumber < 0)) {
        ReturnNumber = this.IsUndefined(___ArbitraryValue, 0);
      }
      return (ReturnNumber);
    }
  }

  CheckDate(DateParam: string) {
    if (DateParam.trim() === '') {
      return (null);
      
    } else {
      return (new Date(DateParam));
    }
  }

  DateCompute(DateParam: Date, DatePart: string, Count: number) {
    if (DateParam ===  null) {
      return (null);
    } else {
      switch (DatePart.toUpperCase()) {
        case 'DAY':
          DateParam.setDate(DateParam.getDate() + Count);
          break;
      }

      return (DateParam);
    }
  }

  GetTimeParts(___Time: string) {
    let __TimeParts: any;
    let __TimeSplit: any;
    __TimeSplit = ___Time.split(':');
    __TimeParts = new Array ();

    if (__TimeSplit.length === 2) {
      __TimeParts['Hour'] = __TimeSplit[0];
      __TimeParts['Minute'] = __TimeSplit[1].substr(0, 2);
      __TimeParts['AMPM'] = __TimeSplit[1].substr(2, 2);
    } else {
      if (parseInt(__TimeSplit[0], 10) < 12) {
        __TimeParts['Hour'] = __TimeSplit[0];
        __TimeParts['AMPM'] = 'AM';
      } else {
        __TimeParts['AMPM'] = 'PM';
        if (parseInt(__TimeSplit[0], 10) > 12) {
          __TimeParts['Hour'] = (parseInt(__TimeSplit[0], 10) - 12).toString();
        }
      }
      if (parseInt(__TimeSplit[0], 10) === 0) {
        __TimeParts['Hour'] = '12';
      }
      __TimeParts['Minute'] = __TimeSplit[1];
    }

    const __Hour: string = __TimeParts['Hour'];
    const __Minute: string = __TimeParts['Minute'];
    const __AMPM: string = __TimeParts['AMPM'];
    let __Validated: boolean = true;

    if (((this.CheckInteger(__Hour, 0) < 1) ||
          (this.CheckInteger(__Hour, 0) > 12) ||
          (this.CheckInteger(__Minute, 0) < 0) ||
          (this.CheckInteger(__Minute, 0) > 59)) &&
        ((__AMPM !== 'AM') &&
        (__AMPM !== 'PM'))) {
      __Validated = false;
    }

    if (__Validated === true) {
      return (__TimeParts);
    } else {
      return (null);
    }
  }

  GetDateTimeParts(___DateTime: string) {
    let __DateParts: any;
    __DateParts = ___DateTime.split('T');

    let __Date: any;
    __Date = this.GetDateParts(__DateParts[0]);

    let ___Time: string
    ___Time = __DateParts[1];
    
    let __TimeParts: any;
    let __TimeSplit: any;
    __TimeSplit = ___Time.split(':');
    __TimeParts = new Array ();

    __TimeParts['Year'] = __Date['Year'];
    __TimeParts['Month'] = __Date['Month'];
    __TimeParts['Day'] = __Date['Day'];

    if (__TimeSplit.length === 2) {
      __TimeParts['Hour'] = __TimeSplit[0];
      __TimeParts['Minute'] = __TimeSplit[1].substr(0, 2);
      __TimeParts['AMPM'] = __TimeSplit[1].substr(2, 2);
    } else {
      if (parseInt(__TimeSplit[0], 10) < 12) {
        __TimeParts['Hour'] = __TimeSplit[0];
        __TimeParts['AMPM'] = 'AM';
      } else {
        __TimeParts['AMPM'] = 'PM';
        if (parseInt(__TimeSplit[0], 10) > 12) {
          __TimeParts['Hour'] = (parseInt(__TimeSplit[0], 10) - 12).toString();
        }
      }
      if (parseInt(__TimeSplit[0], 10) === 0) {
        __TimeParts['Hour'] = '12';
      }
      __TimeParts['Minute'] = __TimeSplit[1];
    }

    const __Hour: string = __TimeParts['Hour'];
    const __Minute: string = __TimeParts['Minute'];
    const __AMPM: string = __TimeParts['AMPM'];
    let __Validated: boolean = true;

    if (((this.CheckInteger(__Hour, 0) < 1) ||
          (this.CheckInteger(__Hour, 0) > 12) ||
          (this.CheckInteger(__Minute, 0) < 0) ||
          (this.CheckInteger(__Minute, 0) > 59)) &&
        ((__AMPM !== 'AM') &&
        (__AMPM !== 'PM'))) {
      __Validated = false;
    }

    if (__Validated === true) {
      return (__TimeParts);
    } else {
      return (null);
    }
  }

  GetDateParts(___Date: string) {
    let __DateParts: any;
    __DateParts = new Array ();
    if (___Date.length === 10) {
      __DateParts['Year'] = ___Date.substr(0, 4);
      __DateParts['Month'] = ___Date.substr(5, 2);
      __DateParts['Day'] = ___Date.substr(8, 2);
    } else {
      let _arrDate: any;
      _arrDate = ___Date.split('-');
      __DateParts['Year'] = _arrDate[0];
      __DateParts['Month'] = _arrDate[1];
      __DateParts['Day'] = _arrDate[2];
    }

  /* copy of ValidateDateParts */
  /* NOTE : cannot use ValidateDateParts as it will become a circular function */
    const __Year: number = this.CheckInteger (__DateParts['Year'], 0);
    const __Month: number = this.CheckInteger (__DateParts['Month'], 0);
    const __Day: number = this.CheckInteger (__DateParts['Day'], 0);
    let __Validated: boolean = false;

    if (((__Year >= 1000) &&
          (__Year <= 9999)) &&
        ((((__Month === 1) ||
            (__Month === 3) ||
            (__Month === 5) ||
            (__Month === 7) ||
            (__Month === 8) ||
            (__Month === 10) ||
            (__Month === 12)) &&
          ((__Day >= 1) &&
            (__Day <= 31))) ||
        (((__Month === 4) ||
          (__Month === 6) ||
          (__Month === 9) ||
          (__Month === 11)) &&
          ((__Day >= 1) &&
          (__Day <= 30))) ||
        ((__Month === 2) &&
          (((__Year % 4 === 0) &&
            ((__Day >= 1) &&
            (__Day <= 29))) ||
          ((__Year % 4 !== 0) &&
            ((__Day >= 1) &&
            (__Day <= 28))))))) {
      __Validated = true;
    }

    if (__Validated === true) {
      return (__DateParts);
    } else {
      return (null);
    }
  }

  ValidateTime(___Time: string) {
    if (___Time.trim() !== '') {
      let __TimeParts: any;
      __TimeParts = this.GetTimeParts (___Time);
      if ((__TimeParts === null) ||
        (Array.isArray(__TimeParts) === false)) {
          return (false);
      } else {
        return (true);
      }
    } else {
      return (false);
    }
  }

  ValidateDate(___Date: string) {
    if (___Date.trim() !== '') {
      let __DateParts: any;
      __DateParts = this.GetDateParts (___Date);
      if ((__DateParts == null) ||
          (Array.isArray(__DateParts) === false)) {
        return (false);
      } else {
        if ((___Date.substr(4, 1) === '-') &&
            (___Date.substr(7, 1) === '-')) {
          return (true);
        } else {
          return (false);
        }
      }
    } else {
      return (false);
    }
  }

  GetMilitaryTime(___Time: string) {
    let __Return: string = '';
    let __TimeParts: any;
    __TimeParts = this.GetTimeParts(___Time);
    if (__TimeParts != null) {

      if ((__TimeParts['AMPM'] === 'AM') &&
          (this.CheckInteger(__TimeParts['Hour'], 0) === 12)) {
        __TimeParts['Hour'] = '00';
      } else if ((__TimeParts['AMPM'] === 'PM') &&
                  (this.CheckInteger(__TimeParts['Hour'], 0) > 12)) {
        __TimeParts['Hour'] = (this.CheckInteger(__TimeParts['Hour'], 0) + 12).toString();
      }

      __Return = __TimeParts['Hour'] + ':' + __TimeParts['Minute'] + ':00';

      return (__Return);
    }
    return (__Return);
  }

  CompareTime(___Time1: string, ___Time2: string) {
/*
    -1        - one or more invalid
    0         - equal
    1         - first greater than second
    2         - second greater than first
*/
    let __Compare: number = -1;
    if ((this.ValidateTime(___Time1) === true) &&
        (this.ValidateTime(___Time2) === true)) {
      let __Time1 = '1900-01-01 ' + this.GetMilitaryTime(___Time1);
      let __Time2 = '1900-01-01 ' + this.GetMilitaryTime(___Time2);

      if (__Time1 === __Time2) {
        __Compare = 0;
      } else if (__Time1 > __Time2) {
        __Compare = 1;
      } else if (__Time1 < __Time2) {
        __Compare = 2;
      }
    }
    return (__Compare);
  }

  ComputeTime(___TimeInput: string, ___TimePart: string, ___Interval: number) {
    let __Return: string = ___TimeInput;

    if ((this.ValidateTime(___TimeInput) === true) &&
        ((___TimePart === 'Hour') ||
         (___TimePart === 'Minute')) &&
        (typeof(___Interval) === 'number')) {
      ___Interval = this.CheckInteger(___Interval, 0);

      if (___Interval !== 0) {
        __Return = '';
        let __TimeParts: any;
        __TimeParts = this.GetTimeParts (___TimeInput);

        let __HourInterval: number = 0;

        let __Hour: number = this.CheckInteger(__TimeParts['Hour'], 0);
        let __Minute: number = this.CheckInteger(__TimeParts['Minute'], 0);

        if (___TimePart === 'Hour') {
          __HourInterval = ___Interval;
        }

        if (___TimePart === 'Minute') {
          __Minute = this.CheckInteger(__TimeParts['Minute'], 0) + ___Interval;
          __HourInterval = Math.floor(__Minute / 60);
          __Minute = __Minute % 60;

          if (__Minute < 0) {
            __HourInterval = __HourInterval - 1;
            __Minute = 60 - Math.abs(__Minute);
          }

          if (__HourInterval !== 0) {
            ___TimePart = 'Hour';
          }
        }

        if ((___TimePart === 'Hour') &&
            (__HourInterval !== 0)) {
          if ((__TimeParts['AMPM'] === 'AM') &&
              (this.CheckInteger(__Hour, 0) === 12)) {
            __Hour = 0;
          } else if ((__TimeParts['AMPM'] === 'PM') &&
                      (__Hour > 12)) {
            __Hour = __Hour + 12;
          }

          __Hour = __Hour + __HourInterval;
          __Hour = __Hour % 24;

          if (__Hour < 0) {
            __Hour = 24 - Math.abs(__Hour);
          }

          if (__Hour >= 12) {
            __TimeParts['AMPM'] = 'PM';
          } else {
            __TimeParts['AMPM'] = 'AM';
          }

          if (__Hour >= 12) {
            __Hour = __Hour - 12;
          }

          if (__Hour === 0) {
            __Hour = 12;
          }
        }

        if (__Hour.toString().length === 1) {
          __Return += '0' + __Hour.toString();
        } else if (__Hour === 0) {
          __Return += '12';
        } else {
          __Return += __Hour.toString();
        }

        __Return += ':';

        if (__Minute.toString().length === 1) {
          __Return += '0' + __Minute.toString();
        } else {
          __Return += __Minute.toString();
        }

        __Return += __TimeParts['AMPM'];
      }
    }
    return (__Return);
  }

  ComputeDateTime(___TimeInput: string, ___TimePart: string, ___Interval: number) {
    let __Return: string = ___TimeInput;

    if ((this.ValidateTime(___TimeInput) === true) &&
        ((___TimePart === 'Hour') ||
         (___TimePart === 'Minute')) &&
        (typeof(___Interval) === 'number')) {
      ___Interval = this.CheckInteger(___Interval, 0);

      if (___Interval !== 0) {
        __Return = '';
        let __TimeParts: any;
        __TimeParts = this.GetDateTimeParts (___TimeInput);

        let __HourInterval: number = 0;

        let __Hour: number = this.CheckInteger(__TimeParts['Hour'], 0);
        let __Minute: number = this.CheckInteger(__TimeParts['Minute'], 0);

        if (___TimePart === 'Hour') {
          __HourInterval = ___Interval;
        }

        if (___TimePart === 'Minute') {
          __Minute = this.CheckInteger(__TimeParts['Minute'], 0) + ___Interval;
          __HourInterval = Math.floor(__Minute / 60);
          __Minute = __Minute % 60;

          if (__Minute < 0) {
            __HourInterval = __HourInterval - 1;
            __Minute = 60 - Math.abs(__Minute);
          }

          if (__HourInterval !== 0) {
            ___TimePart = 'Hour';
          }
        }

        if ((___TimePart === 'Hour') &&
            (__HourInterval !== 0)) {
          if ((__TimeParts['AMPM'] === 'AM') &&
              (this.CheckInteger(__Hour, 0) === 12)) {
            __Hour = 0;
          } else if ((__TimeParts['AMPM'] === 'PM') &&
                      (__Hour > 12)) {
            __Hour = __Hour + 12;

          }

          __Hour = __Hour + __HourInterval;
          __Hour = __Hour % 24;

          if (__Hour < 0) {
            __Hour = 24 - Math.abs(__Hour);
          }

          if (__Hour >= 12) {
            __Hour = __Hour - 12;
          }

          if (__Hour === 0) {
            __Hour = 12;
          }
        }

        if (__Hour.toString().length === 1) {
          __Return += '0' + __Hour.toString();
        } else if (__Hour === 0) {
          __Return += '12';
        } else {
          __Return += __Hour.toString();
        }

        __Return += ':';

        if (__Minute.toString().length === 1) {
          __Return += '0' + __Minute.toString();
        } else {
          __Return += __Minute.toString();
        }

        __Return += __TimeParts['AMPM'];

        __Return = __TimeParts['Year'] + '-' + __TimeParts['Month'] + '-' + __TimeParts['Day'] + ' ' + __Return;
      }
    }
    return (__Return);
  }

  CompareDate(DateStr1: string, DateStr2: string) {
/*
    -10    - both blanks
    -11    - first blank
    -12    - second blank
    0      - equal
    1      - first greater than second
    -1     - second greater than first
*/
    if ((DateStr1.trim() === '') &&
        (DateStr2.trim() === '')) {
      return (-10);

    } else if (DateStr1.trim() === '') {
      return (-11);

    } else if (DateStr2.trim() === '') {
      return (-12);

    } else {
      const DateInt1 = Date.parse(DateStr1.substr(0, 10));
      const DateInt2 = Date.parse(DateStr2.substr(0, 10));

      if (DateInt1 === DateInt2) {
        return (0);
      } else if (DateInt1 > DateInt2) {
        return (1);
      } else if (DateInt1 < DateInt2) {
        return (-1);
      }
    }
  }

  CompareDateTime(DateStr1: string, DateStr2: string) {
/*
    -10    - both blanks
    -11    - first blank
    -12    - second blank
    0      - equal
    1      - first greater than second
    -1     - second greater than first
*/
    if ((DateStr1.trim() === '') &&
        (DateStr2.trim() === '')) {
      return (-10);

    } else if (DateStr1.trim() === '') {
      return (-11);

    } else if (DateStr2.trim() === '') {
      return (-12);

    } else {
      const DateInt1 = Date.parse(DateStr1);
      const DateInt2 = Date.parse(DateStr2);

      if (DateInt1 === DateInt2) {
        return (0);
      } else if (DateInt1 > DateInt2) {
        return (1);
      } else if (DateInt1 < DateInt2) {
        return (-1);
      }
    }
  }

  PhoneFormat(Phone: string) {
    if (Phone.trim() === '') {
      return ('');
    } else {
      return ('(' + Phone.substr(0, 2) + ') ' + Phone.substr(2, 4) + '-' + Phone.substr(6, 4));
    }
  }

  TitleCase(Param: string) {
    if (Param.trim() !== '') {
      let Sentence = Param.toLowerCase().split(' ');
      for (let i = 0; i < Sentence.length; i++) {
        Sentence[i] = this.IsUndefined(Sentence[i][0], '').toUpperCase() + Sentence[i].slice(1);
      }
      let Return = Sentence.join(' ');
      return (Return);
    } else {
      return ('');
    }
  }

  NameCase(Param: string) {
    let Sentence = Param.split(' ');
    for (let i = 0; i < Sentence.length; i++) {
      Sentence[i] = this.IsUndefined(Sentence[i][0], '').toUpperCase() + Sentence[i].slice(1);
    }
    let Return = Sentence.join(' ');
    return (Return);
  }

  ToISOTime(DateParam: Date) {
    const Time: string = this.IsUndefined(DateParam.toISOString().split('T')[1], '');
    return (Time);
  }

  DisplayDate(DateParam: string) {
    if (this.IsUndefined(DateParam, '') !== '') {
      if (Date.parse(DateParam) === NaN) {
        return ('');
      } else {
        return (DateParam.split('T')[0]);
      }
    } else {
      return ('');
    }
  }

  DisplayDateTime(_Date: Date, WithTime: boolean = false) {
    try {
      let _DateString: string = _Date.toISOString().split('T')[0];
      let _TimeString: string = '';
      
      let __DateParts: any;
      __DateParts = new Array ();
      if (_DateString.length === 10) {
        __DateParts['Year'] = _DateString.substr(0, 4);
        __DateParts['Month'] = _DateString.substr(5, 2);
        __DateParts['Day'] = _DateString.substr(8, 2);

      } else {
        let _arrDate: any;
        _arrDate = _DateString.split('-');
        __DateParts['Year'] = _arrDate[0];
        __DateParts['Month'] = _arrDate[1];
        __DateParts['Day'] = _arrDate[2];
      }

      if (WithTime) {
        _TimeString = _Date.toISOString().split('T')[1].split('+')[0];
      }

      return ((__DateParts['Month'] + '/' + __DateParts['Day'] + '/' + __DateParts['Year'] + ' ' + _TimeString).trim());
    } catch (e) {
      return ('');
    }
  }

  DisplayTime(_Date: Date) {
    try {
      return (this.DateTimeToTime(_Date.toISOString()));
    } catch (e) {
      return ('');
    }
  }

  CheckSubscription(GUID: string, Function: string) {
    let Exists: boolean = true;
    if (this.InArray(GUID, this.Subscriptions) === false) {
      this.Subscriptions.push (GUID);
      this.Subscriptions[GUID] = new Array();
      this.Subscriptions[GUID].push(Function.toLowerCase().trim());
      Exists = false;
    } else {
      if (this.InArray(Function.toLowerCase().trim(), this.Subscriptions[GUID]) === false) {
        this.Subscriptions[GUID].push (Function.toLowerCase().trim());
        Exists = false;
      }
    }
    return (Exists);
  }

  Unsubscribes(GUID: string) {
    const Subscriptions = this.CopyObject(this.Subscriptions[GUID]);
    if (Subscriptions.length > 0) {
      for (let Function in Subscriptions) {
        this.events.destroy(GUID.toLowerCase().substring(0, GUID.length - 4) + Subscriptions[Function]);
        this.Subscriptions[GUID].splice(Function);
      }
    }
  }

  WithSelected(Data: any, SelectorColumn: string = 'Selector') {
    if (this.CountArray(Data) > 0) {
      let Count: number = 0;
      let Selected: boolean = false;
      while ((Count < Data.length) &&
             (Selected === false)) {
        if (this.ToBoolean(Data[Count][SelectorColumn]) === true) {
          Selected = true;
        } else {
          Count++;
        }
      }
      return (Selected);
    } else {
      return false;
    }
  }

  GetSelectedIDs(Data: any, IDColumn: string, SelectorColumn: string = 'Selector', Splitter: string = '|') {
    let ReturnIDs: string = '';
    if ((this.CountArray(Data) > 0) &&
        (IDColumn.trim() !== ''))  {
      let Count: number = 0;
      while (Count < Data.length) {
        if (this.ToBoolean(Data[Count][SelectorColumn]) === true) {
          ReturnIDs = ReturnIDs + Data[Count][IDColumn] + Splitter;
        }
        Count++;
      }
    }
    return (ReturnIDs);
  }

  NumericAddCommas(___Val: string) {
    ___Val += '';
    let ___Arr = ___Val.split('.');
    let ___Exp1 = ___Arr[0];
    let ___Exp2 = ___Arr.length > 1 ? '.' + ___Arr[1] : '';
    var __RegExpr = /(\d+)(\d{3})/;
    while (__RegExpr.test(___Exp1))
    {
      ___Exp1 = ___Exp1.replace(__RegExpr, '$1' + ',' + '$2');
    }
    return (___Exp1 + ___Exp2);
  }

  MoneyFormat(___Val: any) {
    switch (typeof(___Val)) {
      case 'string':
        ___Val = this.ParseFloat(___Val).toString();
        break;
      case 'number':
        ___Val = ___Val.toString();
        break;
    }

    if (___Val === null) {
      ___Val = 0;
    }
    ___Val = this.NumericAddCommas (___Val);
    
    var __ValSplit = ___Val.split('.')

    if (__ValSplit.length < 2)
      {___Val += '.00';}
    else
    {
      if (__ValSplit[1].length < 2)
      {
        for (var i = 2 - __ValSplit[1].length; i < 2; i++)
          {__ValSplit[1] += '0';}
      }
      else
        {__ValSplit[1] = __ValSplit[1].substr(0, 2);}
      ___Val = __ValSplit[0] + '.' + __ValSplit[1];
    }
    
    return (___Val);
  }

  ToISOString(_date: Date) {
    if (_date === null) {
      return ('');
    } else {
      let tzo = -_date.getTimezoneOffset(),
          dif = tzo >= 0 ? '+' : '-',
          pad = function(num) {
              let norm = Math.floor(Math.abs(num));
              return (norm < 10 ? '0' : '') + norm;
          };
      return _date.getFullYear() +
          '-' + pad(_date.getMonth() + 1) +
          '-' + pad(_date.getDate()) +
          'T' + pad(_date.getHours()) +
          ':' + pad(_date.getMinutes()) +
          ':' + pad(_date.getSeconds()) +
          dif + pad(tzo / 60) +
          ':' + pad(tzo % 60);
    }
  }

  ToJSString(_date: string) {
    if (this.IsUndefined(_date, '') === '') {
      return (null);
    } else {
      const __Year: string = _date.substr(0, 4);
      let __Month: string;
      switch (parseInt(_date.substr(5, 2), 10)) {
        case 1:
          __Month = 'Jan';
          break;

        case 2:
          __Month = 'Feb';
          break;

        case 3:
          __Month = 'Mar';
          break;

        case 4:
          __Month = 'Apr';
          break;

        case 5:
          __Month = 'May';
          break;

        case 6:
          __Month = 'Jun';
          break;

        case 7:
          __Month = 'Jul';
          break;

        case 8:
          __Month = 'Aug';
          break;

        case 9:
          __Month = 'Sep';
          break;

        case 10:
          __Month = 'Oct';
          break;

        case 11:
          __Month = 'Nov';
          break;

        case 12:
          __Month = 'Dec';
          break;
      }
      const __Day: string = _date.substr(8, 2);
      let __Hour: string = _date.substr(11, 2);
      const __Minute: string = _date.substr(14, 2);
      const __AMPM: string = _date.substr(16, 2);
      if (__AMPM === 'PM') {
        __Hour = (parseInt(__Hour, 10) + 12).toString();
      }

      return (__Day + ' ' + __Month + ' ' + __Year + ' ' + __Hour + ':' + __Minute + ':00 GMT');
    }
  }

  CheckYear (_Year: string) {
    if (isNaN(parseInt(_Year, 10))) {
      return ('');
    } else if (parseInt(_Year, 10) < 1900) {
      return ('');
    } else if (parseInt(_Year, 10) > 9999) {
      return ('');
    } else {
      return (_Year);
    }
  }

  IsPrintableCharacter(KeyCode: number) {
    if ((KeyCode > 47 && KeyCode < 58) ||
        (KeyCode == 32 || KeyCode == 13) ||
        (KeyCode > 64 && KeyCode < 91) ||
        (KeyCode > 95 && KeyCode < 112) ||
        (KeyCode > 185 && KeyCode < 193) ||
        (KeyCode > 218 && KeyCode < 223)) {
      return (true);
    } else {
      return (false);
    }
  }

  SetSublistError(ParentModule: string, ModuleName: string, ErrorType: string, Error: any) {
    if (Array.isArray(this.SublistErrors[ParentModule + ModuleName]) === false) {
      this.SublistErrors[ParentModule + ModuleName] = new Array();
    }
    this.SublistErrors[ParentModule + ModuleName][ErrorType] = Error;
  }

  // ConsoleLog(Message: any) {
  //   if (this.config.Debug) {
  //     console.log('>> ', Message);
  //   }
  // }

  async encodeImage(imageURL: string) {
    let blob = await fetch(imageURL).then(r => r.blob()).then(blobFile => new File([blobFile], imageURL, { type: 'image/jpg' }));
    let imgData: any;
    let file: any = new FileReader();
    file.onloadend = function () {
      imgData = file.result;
    }
    file.readAsDataURL(blob);
    return (imgData);
  }

  Delay(Time: number) {
    return (new Promise(resolve => setTimeout(resolve, Time)));
  }

  DeleteAttachment(ModuleName: string, FileName: string) {
    let TransactionURL: string = this.config.DeleteURL;
    let debug: boolean = this.config.Debug;
    $.ajax ({
      type: 'POST',
      data: 'Parameter=' + ModuleName.toLowerCase() + '/' + FileName.toLowerCase(),
      // data: 'StoredProcedure=' + encodeURIComponent(Schema + '.' + StoredProcedure.trim()) +
      //     '&Parameter=' + encodeURIComponent(Parameter.trim()) +
      //     '&WithParameter=' + encodeURIComponent(WithParameter.toString().trim()) +
      //     '&ServerIncludeLibrary=' + encodeURIComponent(ServerIncludeLibrary.trim()) +
      //     '&ServerExecuteBefore=' + encodeURIComponent(ServerExecuteBefore.trim()) +
      //     '&ServerExecuteAfter=' + encodeURIComponent(ServerExecuteAfter.trim()),
      url: TransactionURL,
      async: true,
      success: function(Result) {
        if (debug === true) {
          console.log('>>>> ', Result, 'Uploaded file: ' + FileName + ' was deleted successfully.');
        }
        // Results = Result;
        // switch (TransactionType) {
        //   case 1:
        //     events.publish (Publish,
        //         {
        //           Data: Result
        //         }
        //       );
        //     break;
        //   case 2:
        //     if (Async === true) {
        //       events.publish (Publish,
        //         {
        //           Data: options.Data,
        //           Response: Result[Tag][0],
        //           CurrentRow: options.Row
        //         }
        //       );
        //     }
        //     break;
        // }
      },
      error: function (err) {
        if (debug === true) {
          console.log('>>>> ', err, 'An error encountered while trying to delete Uploaded file: ' + FileName + '.');
        }
        // if (debug === true) {
        //   console.log('>>>> err: ', err);
        // }
        // switch (TransactionType) {
        //   case 1:
        //     if (debug === true) {
        //       console.log('>>>> search failed!');
        //     }
        //     events.publish (Publish,
        //         {
        //           Data: null
        //         }
        //       );
        //     break;
        //   case 2:
        //     if ((debug === true) &&
        //         (Async === true)) {
        //       console.log ('>>>> transaction failed!');
        //     }
        //     break;
        // }
      }
    });
  }

  OpenAttachment(ModuleName: string, FileExt: string, FileName: string) {
    let newWindow: any;
    switch (FileExt) {
      case 'DOC':
      case 'DOCX':
      case 'XLS':
      case 'XLSX':
      case 'PPT':
      case 'PPTX':
        newWindow = window.open(this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName, '_blank');
        break;
      case 'JPG':
      case 'JPEG':
      case 'PNG':
      case 'GIF':
        newWindow = window.open('', FileName);
        newWindow.document.write (
            '<img src="' + this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName + '" />'
            //  +
            // '<script type="text/jscript">' +
            //   'document.addEventListener("contextmenu", function (e) {' +
            //       'e.preventDefault();' +
            //     '}, false); ' +
            // '</script> '
          );
        break;
      case 'PDF':
        // newWindow = window.open(this.global.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName + '#toolbar=0&navpanes=0', FileName);
        newWindow = window.open(this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName, FileName);
        break;
    }
  }
}
